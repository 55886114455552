import React from "react";
import PropTypes from "prop-types";
import BgImage from "./BgImage";
import SectionShader from "./SectionShader";
import MenuLogoPOT from "./MenuLogoWhitePOT";

const HeroSection = ({
  bgImg,
  bgImgAltTxt,
  title,
  subtitle,
  shaderClassName,
}) => {
  return (
    <header className="relative min-h-screen mob:min-h-0">
      <BgImage img={bgImg} altTxt={bgImgAltTxt} />
      <SectionShader className={shaderClassName} />
      <nav className="absolute top-0 left-0 z-50 mt-4 ml-4 md:ml-10 md:mt-10">
        <MenuLogoPOT />
      </nav>
      <div className="absolute inset-0 flex flex-col justify-center px-2 text-center text-white sm:justify-end sm:mb-8 lg:mb-10 xl:mb-12">
        <h1 className="mb-2 text-5xl font-bold leading-tight md:text-6xl lg:text-7xl">
          {title}
        </h1>
        <h2 className="text-lg md:text-xl lg:text-2xl">{subtitle}</h2>
      </div>
    </header>
  );
};

HeroSection.propTypes = {
  bgImg: PropTypes.object.isRequired,
  bgImgAltTxt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  shaderClassName: PropTypes.string,
};

export default HeroSection;
