import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import HeroSection from "../../components/HeroSection";
import { Section } from "../../components/Section";
import { Seo } from "../../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

const ProgramDetailsSection = () => (
  <Section>
    <div className="flex flex-col items-center md:items-stretch md:flex-row md:justify-center">
      <div className="flex flex-col justify-center text-center md:text-left md:mr-3 lg:mr-6">
        <p className="text-xl uppercase lg:text-2xl">What is</p>
        <p className="text-3xl font-bold uppercase md:mr-0 lg:text-4xl">
          Transition to IT?
        </p>
      </div>
      <div className="hidden mr-3 border-l-2 border-black border-solid md:block lg:mr-6"></div>
      <div className="flex flex-col justify-center mt-4 text-center md:text-left lg:text-lg md:mt-0">
        <p className="w-64">
          Mentorship program to help you with the transition to IT, or upgrading
          your current profession with IT skills.
        </p>
      </div>
    </div>
    <div className="std-mt md:text-lg">
      <p className="text-lg font-bold md:text-xl">About the program</p>
      <p className="mt-4">
        We are super proud to have amazing like-minded partners who share our
        values and goals. Karo Academy, organized by{" "}
        <a
          className="text-orange-700 underline hover:text-blue-700"
          href="https://www.karo-solutions.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Karo-solutions Sweden
        </a>
        , supports the community of Perform On Top with training, courses,
        mentorship, and much more ensuring the comprehensive approach to help
        you with a transition to such a trending IT area, or complement your
        existing skills with relevant IT skills to boost your employability.
      </p>
    </div>
    <div className="std-mt md:text-lg">
      <p className="text-lg font-bold md:text-xl">
        Message for you from Karo Academy
      </p>
      <p className="mt-4">
        <q>
          Our goal is to help you regain control over your future and thrive
          when all has become uncertain. The training allows you to study at
          your own pace which means that you can plan better and increase your
          chances to succeed. The greatest value a mentor will offer you is
          wisdom. A Mentor brings experience, guidance, insights, and advice to
          the table. Take advantage of it. If you have not thought of how you
          will reach your career goals, no worries! Together, we will think of
          how you can get there. The program is free.
        </q>
      </p>
    </div>
    <div className="std-mt md:text-lg">
      <p>
        For more information, questions, and interest please contact either Karo
        Academy directly, or reach out to our project manager via email:
      </p>
      <p>Oleksii Shevtsov: oleksii@performontop.com</p>
    </div>
  </Section>
);

const ProgramPage = ({ data }) => {
  return (
    <>
      <HeroSection
        bgImg={data.heroBgImg}
        bgImgAltTxt="Hero background man programming on a laptop"
        title="Transition to IT"
        subtitle="Change your career or upgrade your skills"
        shaderClassName="opacity-30"
      />
      <main>
        <ProgramDetailsSection />
      </main>
    </>
  );
};

ProgramPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const programQuery = graphql`
  query TransitionToITQuery {
    heroBgImg: file(relativePath: { eq: "transition-to-it-hero-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default ProgramPage;
