import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const BgImage = ({ img, altTxt }) => (
  <GatsbyImage
    image={img.childImageSharp.gatsbyImageData}
    alt={altTxt}
    className="min-h-screen mob:min-h-0"
  />
);

BgImage.propTypes = {
  img: PropTypes.object.isRequired,
  altTxt: PropTypes.string.isRequired,
};

export default BgImage;
