import React from "react";
import PropTypes from "prop-types";

export const Section = ({ children, className }) => {
  const extraClass = className ? className : "";
  return <section className={`std-padding ${extraClass}`}>{children}</section>;
};

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const Title = ({ children }) => (
  <h1 className="text-2xl font-semibold text-center text-orange-600 mob:text-3xl">
    {children}
  </h1>
);

Title.propTypes = {
  children: PropTypes.node,
};

export const SubTitle = ({ children }) => (
  <p className="mx-auto mt-8 text-center mob:text-lg w-12/12 mob:w-10/12 md:w-9/12 md:text-xl">
    {children}
  </p>
);

SubTitle.propTypes = {
  children: PropTypes.node,
};
