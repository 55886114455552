import React from "react";
import PropTypes from "prop-types";

const SectionShader = ({ className }) => {
  const extraClass = className ? className : "";
  return <div className={`absolute inset-0 bg-gray-900 ${extraClass}`}></div>;
};

SectionShader.propTypes = {
  className: PropTypes.string,
};

export default SectionShader;
